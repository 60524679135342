import React from 'react'

import { useIntl } from 'gatsby-plugin-intl'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

const useStyles = makeStyles((theme) => ({
  links: {
    fontFamily: `'Gotham Medium', serif`,
    fontSize: theme.typography.pxToRem(15),
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    margin: '25px 0',
  },
  linksText: {
    fontSize: theme.typography.pxToRem(15),
    textTransform: 'uppercase',
    margin: '0 0.8rem',
    color: 'black',
    fontWeight: 600,
    textDecoration: 'unset',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  linksContainer: {
    justifyContent: 'center',
    [theme.breakpoints.between('xs', 'sm')]: {},
  },
  linksDiv: {
    [theme.breakpoints.between('xs', 'sm')]: {},
  },
}))

const FeesLinks = () => {
  const intl = useIntl()
  const classes = useStyles()

  return (
    <Grid container className={classes.linksContainer}>
      <Grid container className={classes.linksDiv}>
        <Grid container item xs={12} justifyContent="space-between" className={classes.links}>
          <AnchorLink
            to={`/${intl.locale}/fees#notice`}
            title={intl.formatMessage({ id: 'link.legalNotice' })}
            className={classes.linksText}
          >
            {intl.formatMessage({ id: 'link.fees' })}
          </AnchorLink>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default FeesLinks
